<template>
    <psi-stats-card
        icon="mdi-cash-usd"
        title="Household Monthly Income"
        :hide-actions="hideActions"
        :stat="$dollarFormat($_.get(summary, 'income_total'))"
        @stat-action:clicked="$emit('view:income')"
    >
        <template v-slot:qualifier>
            <v-chip
                class="ml-1 alternate1--text"
                :class="
                    summary.income_verified
                        ? 'text--darken-2'
                        : 'text--darken-3'
                "
                :color="
                    summary.income_verified
                        ? 'alternate1 lighten-2'
                        : 'alternate1 lighten-4'
                "
            >
                <v-icon class="mr-1">
                    {{
                        summary.income_verified
                            ? "mdi-check-circle"
                            : "mdi-alert-circle"
                    }}
                </v-icon>
                {{ summary.income_verified ? "VERIFIED" : "NOT VERIFIED" }}
            </v-chip>
        </template>
    </psi-stats-card>
</template>
<script>
export default {
    name: "household-monthly-income",
    components: {},
    props: {
        summary: {
            type: Object,
            required: true,
        },
        hideActions: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>