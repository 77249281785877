var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("psi-stats-card", {
    attrs: {
      icon: "mdi-cash-usd",
      title: "Household Monthly Income",
      "hide-actions": _vm.hideActions,
      stat: _vm.$dollarFormat(_vm.$_.get(_vm.summary, "income_total"))
    },
    on: {
      "stat-action:clicked": function($event) {
        return _vm.$emit("view:income")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "qualifier",
        fn: function() {
          return [
            _c(
              "v-chip",
              {
                staticClass: "ml-1 alternate1--text",
                class: _vm.summary.income_verified
                  ? "text--darken-2"
                  : "text--darken-3",
                attrs: {
                  color: _vm.summary.income_verified
                    ? "alternate1 lighten-2"
                    : "alternate1 lighten-4"
                }
              },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.summary.income_verified
                          ? "mdi-check-circle"
                          : "mdi-alert-circle"
                      ) +
                      " "
                  )
                ]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.summary.income_verified ? "VERIFIED" : "NOT VERIFIED"
                    ) +
                    " "
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }